import Vue from 'vue'
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import SpinnerContainer from './components/SpinnerContainer.vue';

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// leaflet css
import 'leaflet/dist/leaflet.css'
import 'mapbox-gl/dist/mapbox-gl.css'

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Loader
Vue.use(LottieVuePlayer);

// Composition API
Vue.use(VueCompositionAPI)

Vue.component("SpinnerContainer", SpinnerContainer)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

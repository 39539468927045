import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    orders: [],
    kits: [],
  }
}

const state = getDefaultState()

const getters = {
  getOrders(state) {
    return state.orders;
  },
  getKits(state) {
    return state.kits;
  },
}

const mutations = {
  setOrders(state, orders) {
    state.orders = orders;
  },

  setKits(state, kits) {
    state.kits = kits;
  },
}

const actions = {
  fetchOrders(context, queryParams) {
    return ApiService.get('order', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setOrders', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchKits(context, queryParams) {
    return ApiService.get('order/kits', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setKits', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  hasMissedFileFetch(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('order/kits/hasmissedfile', {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedPage: 'INFO',

    uploadedFileList: [],
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

// export const API_URL = 'https://localhost:5001/api';
export const API_URL = 'https://api.illustrativedna.com/api';
// export const API_URL = 'https://apitest.illustrativedna.com/api';

export const GOOGLE_API_KEY = process.env.NODE_ENV === 'development' ? 'AIzaSyAtgBHdXZtVI3tSA2APEbloj441Bp3E35A' : 'AIzaSyCq0AU-7Vq6ggAEgfCSPIxHFQbSKseyuBk';

export const regionDefaultId = 1;
export const regionGrupDefaultId = 3;

export const PRODUCT_CODES = {
  UPLOAD_DA_COORDINATES: 'upload_coordinates',
  UPLOAD_DA_RAW_DATA: 'Upload_Raw_Data',
  UPLOAD_TRAIT_RAW_DATA: 'traits_health',
};

export const DA_CHECKOUT_STEPS = {
  SELECT_SERVICE: 'select-service',
  UPLOAD: 'upload',
  ACCOUNT: 'account',
  CONFIRM: 'confirm',
};

export const TRAIT_CHECKOUT_STEPS = {
  UPLOAD: 'upload',
  ACCOUNT: 'account',
  CONFIRM: 'confirm',
};

export const DA_RESULT_TABS = {
  PERIODICAL: { code: 'PERIODICAL', title: 'Supervised Periodical Breakdown' },
  FARMER_HGS: { code: 'FARMER_HGS', title: 'Hunter-Gatherer & Farmer' },
  MIXED_MODE: { code: 'MIXED_MODE', title: 'Unsupervised Ancient' },
  MODERN_MIXED_MODE: { code: 'MODERN_MIXED_MODE', title: 'Unsupervised Modern' },
  MODERN_DIY_MODEL: { code: 'MODERN_DIY_MODEL', title: 'DIY Modern Model' },
  ANCIENT_DIY_MODEL: { code: 'ANCIENT_DIY_MODEL', title: 'DIY Ancient Model' },
  MODERN_GENETIC_DISTANCES: { code: 'MODERN_GENETIC_DISTANCES', title: 'Closest Modern Populations' },
  ANCESTRY_GENETIC_DISTANCES: { code: 'ANCESTRY_GENETIC_DISTANCES', title: 'Closest Ancient Populations' },
  PLOT_PCA: { code: 'PLOT_PCA', title: 'Principal Component Analysis' },
  ENCYCLOPEDIA: { code: 'ENCYCLOPEDIA', title: 'ENCYCLOPEDIA' },
};

export const TRAITS_RESULT_TABS = {
  TRAITS: { code: 'TRAITS', title: 'Traits' },
  VITAMINS_MINERALS: { code: 'VITAMINS_MINERALS', title: 'Vitamins & Minerals' },
};

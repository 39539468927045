<template>
  <div class="spinner-container">
    <div class="spinner-body">
      <div class="bar" v-for="n in 12" :key="n" :style="getStyle(n)"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getStyle(n) {
      const rotate = (n - 1) * 30; // Her çubuğu 30 derece artırarak döndür
      const delay = (n - 1) * 0.0833; // Animasyon gecikmesi
      return {
        transform: `rotate(${rotate}deg) translate(0, -80%)`, // translate değerini -110% yaptık
        animationDelay: `${delay}s`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-body {
  position: relative;
  width: 50px;
  height: 50px;
}

.bar {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6%; // Çubuğun genişliği
  height: 18%; // Çubuğun yüksekliği
  background-color: var(--spinner-container);
  border-radius: 5px;
  opacity: 0.2;
  transform-origin: center bottom;
  animation: fade 1s linear infinite;
}

// Animasyon tanımı
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>

import ApiService from '@/common/api.service'
/* eslint-disable */
const getDefaultState = () => {
  return {
    ancientSourceDatasResult: [],
    ancientSourceDatas: [],
    closestAncientPopResults: null,
    closestAncientImagePopResults: null,
    closestAncientPopNumber: '30',
  }
};

const state = getDefaultState();

const getters = {
  getClosestAncientPopResults(state) {
    return state.closestAncientPopResults;
  },
  getClosestAncientPopImageResults(state) {
    return state.closestAncientImagePopResults;
  },
}

const mutations = {
  setAncientSourceDatas(state, sourceDatas) {
    if (sourceDatas.length) {
      state.ancientSourceDatas = sourceDatas[0].source;
    }
    
    const resolveSource = this.state.orderResult.IllusDnaService.codeDataToArray(state.ancientSourceDatas);
    if (resolveSource.isSuccess) {
      this.state.orderResult.IllusDnaService.onSetSource(resolveSource.result);
      state.ancientSourceDatasResult = resolveSource.result;
    }
  },
  setClosestAncientImageResults(state, result) {
    state.closestAncientImagePopResults = result;
  },
  runAncientDist(state) {
    this.state.orderResult.IllusDnaService.dataObject.maxDistOutPut = state.closestAncientPopNumber
    const result = this.state.orderResult.IllusDnaService.calculateDistance(0);
    state.closestAncientPopResults = result;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchAncientSourceDatas(context, queryParams) {
    queryParams.dataVersion = context.rootGetters['orderResult/getDataVersion']
    return ApiService.get('sourcedata', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setAncientSourceDatas', response.result);
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchSampelCoordinates(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post(`AncientProxDataSampleCoordinate`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import Vue from 'vue'
import Vuex from 'vuex'

// General
import app from './app';
import loader from './app/loader.module';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import notification from './notification/notification.module'
import ticket from './ticket/ticket.module'

import account from './general-store/account/account.module'
import profileSettings from './general-store/profile-settings/profilesettings.module';
import products from './general-store/product/product.module'
import orders from './general-store/order/order.module'

import deepACheckout from './general-store/checkout/deepACheckout.module'
import traitCheckout from './general-store/checkout/traitCheckout.module'
import g25PartnerCheckout from './general-store/checkout/g25PartnerCheckout.module';

import updateV2 from './general-store/update-v2/updateV2.module';

import operation from './operation/operation.module'
import orderResult from './results/g25/orderResult.module';
import orderResultTrait from './results/traits-healths/orderResultTrait.module';

import ancestrybreakdown from './results/g25/break-down/ancestrybreakdown.module';
import modernbreakdown from './results/g25/break-down/modernbreakdown.module';
import customancestrybreakdown from './results/g25/break-down/customancestrybreakdown.module';
import periodicalancestrybreakdown from './results/g25/break-down/periodicalancestrybreakdown.module';
import farmerhgs from './results/g25/farmer-hgs/farmerhgs.module';
import ancientpopulation from './results/g25/closest-populations/ancientpopulation.module'
import modernpopulation from './results/g25/closest-populations/modernpopulation.module'
import pcaplot from './results/g25/pca/pcaplot.module'
import ancientMixedmode from './results/g25/mixed-mode/ancientMixedmode.module'
import modernMixedmode from './results/g25/mixed-mode/modernMixedmode.module'
import encyclopedia from './results/g25/encyclopedia/encyclopedia.module';

import traitsAndHealths from './results/traits-healths/traits-and-healths/traitsAndHealths.module';
import traitsSurvey from './results/traits-healths/traits-and-healths/traitsSurvey.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // General
    app,
    loader,
    appConfig,
    verticalMenu,

    notification,
    ticket,
    
    account,
    profileSettings,
    products,
    orders,

    deepACheckout,
    traitCheckout,
    g25PartnerCheckout,

    updateV2,

    operation,
    orderResult,
    orderResultTrait,

    ancestrybreakdown,
    modernbreakdown,
    customancestrybreakdown,
    periodicalancestrybreakdown,
    farmerhgs,
    ancientpopulation,
    modernpopulation,
    pcaplot,
    ancientMixedmode,
    modernMixedmode,
    encyclopedia,

    traitsAndHealths,
    traitsSurvey,
  },
  strict: process.env.DEV,
})

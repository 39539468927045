/* eslint-disable */
import { getArraySum, plotlyMarkers, plotlyMarkers3d, pcaColors } from './utils'

const PcaService = {
  sourceObject: {
    sourceData: null,
    projectedData: null,
    pcaData: null,
    projectSources: true,
    selectedTargetTitle: null,
    flipX: false,
    flipY: false,
    flipZ: false,
    dimensions: 2,
    dimX: 1,
    dimY: 2,
    dimZ: 3,
    pcaDataHasChanged: true,
    traces: [],
    currentDimX: 1,
    currentDimY: 2,
    currentDimZ: 3,
    active3d: false,
    datarevision: 0,
    layout2d: {},
    tabNames: {'source': 'source', 'projected': 'projected', 'pcadata': 'pca data'},
    plotOptions: {
      edits: {
        annotationTail: true
      },
      scrollZoom: true,
      responsive: true,
      modeBarButtonsToRemove: ['hoverClosestCartesian', 'hoverCompareCartesian'],//['autoScale2d', 'lasso2d', 'toImage', 'resetViews', 'select2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
      toImageButtonOptions: {
        format: 'png',
        filename: 'Illustrative_PCA',
        height: 1800,
        width: 1600,
        scale: 1
      },
      modeBarButtonsToAdd: [
        {
          name: 'Download',
          icon: Plotly.Icons.camera,
          click: function(gd) {
            let scene = document.getElementById('graphDiv'), 
              saveSize = prompt("Please enter plot size in px (width x height)", Math.round(scene.clientWidth) + " x " + Math.round(scene.clientHeight));
            if (saveSize != null) {
              saveSize = saveSize.split("x");
              if (saveSize.length == 2) {
                saveSize[0] = Number(saveSize[0].trim());
                saveSize[1] = Number(saveSize[1].trim());
                if (Number.isInteger(saveSize[0]) &&  saveSize[0] > 1 && Number.isInteger(saveSize[1]) &&  saveSize[1] > 1 ) {
                  Plotly.downloadImage('graphDiv',{format:'png',height:saveSize[1],width:saveSize[0],filename:'Illustrative_PCA',scale:1});
                  return;
                }
              }
              alert("Input not in 'width x height' format.");
            }
          }
        }
      ],
    },
    pcaColorMode: 'light',
    isEnableConvexHull: false,
    colorList:['#166f4e', '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', 
              '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', '#da0017', 
              '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061',
              '#166f4e', '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', 
              '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', '#da0017', 
              '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061',
              '#166f4e', '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', 
              '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', '#da0017', 
              '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061',
              '#166f4e', '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', 
              '#da0017', '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061', '#166f4e', '#da0017', 
              '#5619a4', '#de0077', '#569918', '#9824d9', '#6f6317', '#535353', '#2b57a0', '#bb5805', '#7e0061']
  },

  onSetSourceDatas(sourceData, projectedData) {
    this.sourceObject.sourceData = sourceData;
    this.sourceObject.projectedData = projectedData;
  },

  textareaToArray(textareaData, textareaId) {
    const srObject = this.sourceObject;
    let i, j, m, n, text1, text2, diff12, text3, diff23, 
        text4, diff34, lines, columnNum, result = {lines: [], message: "", errors: 0};
    const textarea =textareaData;
    textareaId = srObject.tabNames[textareaId].toUpperCase();
    text1 = textarea.trim().replace(/\r\n/g,"\n").replace(/^\/\/.*\n?/gm, '').trim().replace(/\"/g,"").replace(/\</g, "&lt;").replace(/\>/g, "&gt;");
    text2 = text1.replace(/[^\S\n]/g, "");
    diff12 = text1.length - text2.length;
    if (diff12 > 0) {
    }
    text3 = text2.replace(/\n+/g, "\n");
    diff23 = text2.length - text3.length;
    if (diff23 > 0) {
    }
    text4 = text3.replace(/\,+/g, "\,");
    diff34 = text3.length - text4.length;
    if (diff34 > 0) {
      result.errors = 1;
      result.lines = false;
      return result;
    }
    result.lines = text4.split("\n");
    columnNum = result.lines[0].split(",").length;
    if (columnNum === 1) {
      result.errors = 1;
      result.lines = false;
      return result;
    }
    if (columnNum === 2) {
      result.errors = 1;
      result.lines = false;
      return result;
    }
    for (i = 0, n = result.lines.length; i < n; i++) {
      result.lines[i] = result.lines[i].split(",");
      if (result.lines[i].length !== columnNum) {
        result.errors = 1;
        result.lines = false;
        return result;
      }
      for (j = 1, m = result.lines[i].length; j < m; j++) {
        if (isNaN(result.lines[i][j])) {
          result.errors = 1;
          result.lines = false;
          return result;
        }
      }
    }
    for (i = 0, n = result.lines.length; i < n; i++) {
      for (j = 1; j < columnNum; j++) {
        result.lines[i][j] = Number(result.lines[i][j]);
      }
    }
    
    return {
      isSuccess: result.errors === 0 ? true : false,
      message: result.message,
      lines: result.lines,
    };
  },

  hasPCAdataChanged(yesno) {
    this.sourceObject.pcaDataHasChanged = yesno;
    // this.setOptDisp(yesno ? "none" : "inline-block");
  },

  setOptDisp (display) {
    document.getElementById("optX").style.display = display;
    document.getElementById("optY").style.display = display;
    if (display == "none") {
      document.getElementById("to3d").style.display = display;
      this.to3d(true);  
    }
    if (display == "inline-block" && this.sourceObject.dimensions > 2) {
      document.getElementById("to3d").style.display = display;
    }
  },

  to3d (reset = false) {
    const srcObject = this.sourceObject;
    if (srcObject.active3d || reset) {
      srcObject.active3d = false;
      document.getElementById("optZ").style.display = "none";
      document.getElementById("to3d").innerHTML = "3d";
      if (!reset) this.traceType("scatter");
    } else {
      srcObject.active3d = true;
      document.getElementById("optZ").style.display = "inline-block";
      document.getElementById("to3d").innerHTML = "2d";
      this.traceType("scatter3d");
      if (srcObject.dimensions > 2 && (srcObject.dimZ == srcObject.dimX || srcObject.dimZ == srcObject.dimY)) {
        for (let i = 1; i < srcObject.dimensions + 1; i++) {
          if (i != srcObject.dimX && i != srcObject.dimY) {
            srcObject.dimZ = i;
            this.updateXYPC();
            break;
          }
        }
      }
    }
    if (!reset) {
      this.plotPCA(true);
    }
  },

  traceType (type) {
    const srcObject = this.sourceObject;
    for (let i = 0; i < srcObject.traces.length; i++) {
      srcObject.traces[i].type = type;
      if (type == "scatter" && srcObject.traces[i].mode == "markers") {
        srcObject.traces[i].marker = srcObject.traces[i].marker2d;
      } else if (srcObject.traces[i].mode == "markers") {
        srcObject.traces[i].marker = srcObject.traces[i].marker3d;
      }
    }
  },

  updateXYPC () {
    document.getElementById("optX").firstElementChild.innerHTML = "X:&nbsp;PC" + this.sourceObject.dimX;
    document.getElementById("optY").firstElementChild.innerHTML = "Y:&nbsp;PC" + this.sourceObject.dimY;
    document.getElementById("optZ").firstElementChild.innerHTML = "Z:&nbsp;PC" + this.sourceObject.dimZ;
  },

  runPCA() {
    const srObject = this.sourceObject;
    const response = {
      message: '',
      isSuccess: true,
    };
    let errors = 0, message = "", sourceArray = false, projectedArray = false;
    sourceArray = this.textareaToArray(srObject.sourceData, 'source').lines;
    projectedArray = this.textareaToArray(srObject.projectedData, 'projected').lines;

    if (projectedArray && sourceArray[0].length !== projectedArray[0].length) {
      return response;
    }

    let projectedNames = [], i, n, tempLine;
    if (projectedArray) {
      for (i = 0, n = projectedArray.length; i < n; i++) {
        projectedNames.push('label@' + srObject.selectedTargetTitle);
        projectedArray[i].shift();
      }
    } else {
      projectedArray = [];
    }
    if (srObject.projectSources) {
      for (i = 0, n = sourceArray.length; i < n; i++) {
        projectedNames.push(sourceArray[i][0].replaceAll('*', '  '));
        sourceArray[i].shift();
        projectedArray.push(sourceArray[i]);
      }
    } else {
      for (i = 0, n = sourceArray.length; i < n; i++) {
        sourceArray[i].shift();
      }
    }
    
    const pca = new ML.PCA(sourceArray);
    const variance = pca.getExplainedVariance();
    let componentsNum = 1, totalVariance = 0;
    if (pca.U.columns > 1 && !isNaN(variance[0]) && variance[0] < 1) {
      for (i = 0, n = variance.length; i < n; i++) {
        totalVariance += variance[i];
        if (totalVariance < 0.98 && variance[i] > 0.01) {
          componentsNum++;
        } else {
          break;
        }
      }
      if (componentsNum > 1) {
        const projected = pca.predict(projectedArray), 
          cumulativeVariance = pca.getCumulativeVariance();
        let msg = "", j;
        for (i = 0, n = projected.rows; i < n; i++) {
          msg += projectedNames[i];
          for (j = 0; j < componentsNum; j++) {
            msg += "," + (projected.data[i][j]).toFixed(6);
          }
          msg += "\n";
        }
        msg += "//\n// PC, Explained variance, Cumulative variance\n";
        for (i = 0; i < componentsNum; i++) {
          msg += "// PC" + (i + 1) + ", " + (variance[i] * 100).toFixed(1) + "%, " + (cumulativeVariance[i] * 100).toFixed(1) + "%\n"; 
        }
        msg += "//\n";
        this.sourceObject.pcaData = msg;
        this.hasPCAdataChanged(true);
      } else {
        message += "ERROR! Not enough data to perform PCA. ";
        showNotification(message, 1);
        return;
      }
    } else {
      response.isSuccess = false
      return response;
    }
  },

  plotPCA(switch2d3d = false) {
    const srObject = this.sourceObject;
    
    let i, j, k, l, m, n, currentName;
    function clearFlip () {
      if (srObject.flipX) document.getElementById("flipX").click();
      if (srObject.flipY) document.getElementById("flipY").click();
      if (srObject.flipZ) document.getElementById("flipZ").click();
    }

    function getColumn(arr, col) {
      let column = [];
      for (let item of arr) {
        column.push(item[col]);
      }
      return column;
    };
    function getAverage(arr) {
      function arrSum (total, num) {
        return total + num;
      }
      return arr.reduce(arrSum) / arr.length;
    };
    
    function newLayout2d () {
      this.xaxis = { zeroline: false };
      this.yaxis = { zeroline: false };
      this.hovermode = pcaColors(srObject.pcaColorMode).hovermode;
      this.dragmode = pcaColors(srObject.pcaColorMode).dragmode;
      this.legend = { font: { size: 10, color: pcaColors(srObject.pcaColorMode).legend_color } };
      this.paper_bgcolor = pcaColors(srObject.pcaColorMode).paper_bgcolor;
      this.plot_bgcolor = pcaColors(srObject.pcaColorMode).plot_bgcolor;
      this.font = { size: 10, color: pcaColors(srObject.pcaColorMode).font_color };
      this.margin = { l: 50, t: 20, b: 20 };
      this.datarevision = srObject.datarevision;
      this.shapes = [];
      this.annotations = [];
    }
    
    function newLayout3d () {
      this.xaxis = { zeroline: false };
      this.yaxis = { zeroline: false };
      this.zaxis = { zeroline: false };
      this.hovermode = "closest";
      this.dragmode = "turntable";
      this.legend = { font: { size: 10, color: "#ddd" }, itemsizing: "constant" };
      this.margin = { l: 10, t: 20, b: 10 };
      this.paper_bgcolor = "#181818";
      this.plot_bgcolor = "#181818";
      this.scene = {
        annotations: [],
        aspectmode: "data",
        bgcolor: "#rgba(0,0,0,0)",
        xaxis: {
          color: "#444"
        },
        yaxis: {
          color: "#444"
        },
        zaxis: {
          color: "#444"
        },
        camera: { 
          projection: {
            type: "perspective"
          }
        }
      }
      this.datarevision = srObject.datarevision;
    }

    function trace (name, dimensions, marker = false, marker3d = false) {
      this.text = [];
      this.x = [];
      this.y = [];
      this.z = [];
      this.mode = "markers";
      this.type = "scatter";
      this.hoverinfo = "text";
      this.name = name;
      this.visible = srObject.isEnableConvexHull ? 'legendonly' : true;
      if (marker != false) {
        this.marker = { size: marker[1], symbol: 'circle', opacity: 0.75, color: '#57b894' };
        this.marker2d = { size: marker[1], symbol: 'circle', opacity: 0.75 };
        this.marker3d = { size: marker3d[1], symbol: marker3d[0], opacity: 0.75 };
      }
      this.storePCAdata = Array(dimensions);
      for (let i = 0; i < dimensions; i++) {
        this.storePCAdata[i] = [];
      }
    }

    function newShape(points, color) {
      if (points.length == 1) {
        let newShapeObj = {
          role: 'convex',
          type: 'line',
          xref: 'x',
          yref: 'y',
          x0: points[0],
          y0: points[1],
          x1: points[0],
          y1: points[1],
          line: {
            color: color,
            width: 2
          },
          opacity: 0.2,
          visible: true,
          traceID: 1
        }
        return newShapeObj;
      }
      else if (points.length == 2) {
        let newShapeObj = {
          role: 'convex',
          type: 'line',
          xref: 'x',
          yref: 'y',
          x0: points[0][0],
          y0: points[0][1],
          x1: points[1][0],
          y1: points[1][1],
          line: {
            color: color,
            width: 2
          },
          opacity: 0.2,
          visible: true,
          traceID: 2
        }
        return newShapeObj;
      } else {
        let coordinates = [], convexHull, newShapeObj;
        for (let item of points) {
          coordinates.push([item[0], item[1]]);
        }
        convexHull = d3.polygonHull(coordinates);
        for (let item of convexHull) {
          item = item.join(',');
        }
        convexHull = convexHull.join(' L');
        convexHull = 'M ' + convexHull + ' Z';
        newShapeObj = {
          role: 'convex',
          type: 'path',
          path: convexHull,
          fillcolor: (color + '30'),
          line: {
            color: (color + '45'),
            width: 1
          },
          opacity: 0.9,
          visible: true,
          traceID: points.length
        }
        return newShapeObj;
      }
    };

    function newAnnotation(points, name, color) {
      const isTarget = name === srObject.selectedTargetTitle;
      if(isTarget) {
        let hasTarget = srObject.layout2d.annotations.find(x => x.x === points[0][0] && x.y === points[0][1]);
        if(hasTarget) {
          return;
        }
      }
      
      const fontColors = srObject.pcaColorMode === 'light'? ['red', color] : ['red', 'white'];
      const bgcolor = srObject.pcaColorMode === 'light'? 'rgba(255,255,255,0.75)' : 'rgba(255,255,255,0)';
      let newAnnotation = {
        x: getAverage(getColumn(points, 0)),
        y: getAverage(getColumn(points, 1)),
        text: name,
        textangle: 0,
        ax: 0,
        ay: isTarget ? (points.length > 8 ? 0 : -27) : (points.length > 1 ? 0 : -27),
        font: {
          color: isTarget ? fontColors[0] : fontColors[1],
        },
        arrowcolor: color + 'aa',
        arrowside: 'end+start',
        arrowsize: 1,
        startarrowsize: 1,
        arrowwidth: isTarget ? 2: 1,
        arrowhead: 6,
        startarrowhead: 0,
        standoff: 0,
        startstandoff: 0,
        borderpad: 7,
        bordercolor: 'rgba(255,255,255,1)',
        visible: true,
        traceID: points.length,
        mode1: function () {
          this.showarrow = true,
          this.borderwidth = 0,
          this.font.size = isTarget ? 16: 11,
          this.bgcolor = isTarget ? bgcolor : bgcolor
        },
      }
      newAnnotation.mode1();
      return newAnnotation;
    };

    function AddLayoutFeatures(trace) {
      let point = [];
      
      for(let index = 0; index < trace.x.length; index++) {
        let objPoint = [trace.x[index], trace.y[index]];
        point.push(objPoint);
      }

      if(point.length > 1) {
        if (srObject.isEnableConvexHull) {
          let color = trace.marker ? trace.marker.color: '#57b894';
          srObject.layout2d.shapes.push(newShape(point, color));
          if (trace.name != '')
            srObject.layout2d.annotations.push(newAnnotation(point, trace.name, color));
        } else {
          let color = trace.marker ? trace.marker.color: '#57b894';
          if (trace.name != '')
            srObject.layout2d.annotations.push(newAnnotation(point, trace.name, color));
        }
      } else {
          let color = trace.marker ? trace.marker.color: '#57b894';
          if (trace.name != '')
            srObject.layout2d.annotations.push(newAnnotation(point, trace.name, color));
      }
    };  
    
    function addToTrace (currentTrace, point) {
      let i, n;
      currentTrace.text.push(point[0].replaceAll('*', '  '));
      for (i = 0, n = currentTrace.storePCAdata.length; i < n; i++) {
        currentTrace.storePCAdata[i].push(point[i + 1]);
      }
    }
    
    function flipIt (dim) {
      dim--;
      for (let i in traces) {
        for (let j in traces[i].storePCAdata[dim]) {
          traces[i].storePCAdata[dim][j] *= -1;
        }
      }
    }
    
    function switchIt (which, dim) {
      dim--;
      for (let i in srObject.traces) {
        srObject.traces[i][which] = srObject.traces[i].storePCAdata[dim];
      }
    }

    if (srObject.pcaDataHasChanged) { 
      srObject.traces = [], srObject.layout2d = {}, srObject.layout3d = {};
      let sourceArray = this.textareaToArray(srObject.pcaData, 'pcadata').lines;
      clearFlip();
      srObject.dimX = 1;
      srObject.dimY = 2;
      srObject.dimZ = 2;

      this.updateXYPC();
      srObject.dimensions = sourceArray[0].length - 1;

      // this.setOptDisp("inline-block");

      let labels = [], labelsOnly = [], labelsGroup = [];
      for (let id = sourceArray.length - 1; id > -1; id--) {
        if (sourceArray[id][0].substr(0,6) == 'label@') {
          sourceArray[id][0] = sourceArray[id][0].substr(6);
          labels.push(sourceArray[id].slice());
        }
        if (sourceArray[id][0].substr(0,10) == 'labelonly@') {
          sourceArray[id][0] = sourceArray[id][0].substr(10);
          labelsOnly.push((sourceArray.splice(id, 1))[0]);
        }
      }

      sourceArray.sort(function(a, b) {
        return a[0].localeCompare(b[0]);
      });

      for (i = 0, n = sourceArray.length, j = 0, k = 0, l = plotlyMarkers3d.length, m = plotlyMarkers.length; i < n; i++, j++, k++) {
        if (j == m) j = 0;
        if (k == l) k = 0;
        let newTrace = new trace(sourceArray[i][0].split(":")[0], srObject.dimensions, plotlyMarkers[j], plotlyMarkers3d[k]);
        addToTrace(newTrace, sourceArray[i]);
        while (i + 1 < n && newTrace.name == sourceArray[i + 1][0].split(":")[0]) {
          i++;
          addToTrace(newTrace, sourceArray[i]);
        }
        newTrace.x = newTrace.storePCAdata[0];
        newTrace.y = newTrace.storePCAdata[1];
        newTrace.z = newTrace.storePCAdata[1];
        srObject.traces.push(newTrace);
      }

      let groupTextTrace = new trace("", srObject.dimensions);
      groupTextTrace.visible = false;
      groupTextTrace.mode = "text";
      groupTextTrace.hoverinfo = "skip";
      groupTextTrace.textposition = "middle center";

      groupTextTrace.textfont = {color: srObject.label_font_color};

      for (let i = srObject.traces.length - 1; i > -1; i--) {
        let newPoint = [srObject.traces[i].name];
        for (let j = 0; j < srObject.dimensions; j++) {
          newPoint.push(getArraySum(srObject.traces[i].storePCAdata[j]) / srObject.traces[i].storePCAdata[j].length);
        }
        /*if (traces[i].storePCAdata[0].length > 1) {
          groupTextTrace.textposition.push("middle center");
        } else {
          groupTextTrace.textposition.push("top center");
        }*/
        
        addToTrace(groupTextTrace, newPoint);
      }
      groupTextTrace.x = groupTextTrace.storePCAdata[0];
      groupTextTrace.y = groupTextTrace.storePCAdata[1];
      groupTextTrace.z = groupTextTrace.storePCAdata[1];
      srObject.traces.push(groupTextTrace);

      let sampleTextTrace = new trace("", srObject.dimensions);
      sampleTextTrace.visible = false;
      sampleTextTrace.mode = "text";
      sampleTextTrace.hoverinfo = "skip";
      sampleTextTrace.textposition = "middle right";
      sampleTextTrace.textfont = {color: pcaColors(srObject.pcaColorMode).label_font_color};

      for (let i = sourceArray.length - 1; i > -1; i--) {
        let tempLine = sourceArray[i].slice();
        addToTrace(sampleTextTrace, tempLine);
      }
      sampleTextTrace.x = sampleTextTrace.storePCAdata[0];
      sampleTextTrace.y = sampleTextTrace.storePCAdata[1];
      sampleTextTrace.z = sampleTextTrace.storePCAdata[1];
      srObject.traces.push(sampleTextTrace);

      if (labelsOnly.length > 0) {
        let textTrace = new trace("Custom Group Labels", srObject.dimensions);
        textTrace.visible = false;
        textTrace.mode = "text";
        textTrace.hoverinfo = "skip";
        textTrace.textposition = [];
        textTrace.textfont = {color:  pcaColors(srObject.pcaColorMode).label_font_color};
        textTrace.textposition = "middle center";
        for (let i = labelsOnly.length - 1; i > -1; i--) {
          addToTrace(textTrace, labelsOnly[i]);
        }
        textTrace.x = textTrace.storePCAdata[0];
        textTrace.y = textTrace.storePCAdata[1];
        textTrace.z = textTrace.storePCAdata[1];
        srObject.traces.push(textTrace);
      }

      if (labels.length > 0) {
        let textTrace = new trace(labels[0][0], srObject.dimensions);
        textTrace.visible = false;
        textTrace.mode = "text";
        textTrace.hoverinfo = "skip";
        textTrace.textposition = [];
        textTrace.textfont = {color:  pcaColors(srObject.pcaColorMode).label_font_color};
        textTrace.textposition = "middle right";
        for (let i = labels.length - 1; i > -1; i--) {
          addToTrace(textTrace, labels[i]);
        }
        textTrace.x = textTrace.storePCAdata[0];
        textTrace.y = textTrace.storePCAdata[1];
        textTrace.z = textTrace.storePCAdata[1];
        srObject.traces.push(textTrace);
      }
      // clearNotification();
      // if (taTApcadata.message.length > 0) {
      //   showNotification(taTApcadata.message);
      // }
      srObject.layout2d = new newLayout2d;
      srObject.layout3d = new newLayout3d;
      this.hasPCAdataChanged(false);
    }
    if (srObject.flipX) flipIt(srObject.dimX);
    if (srObject.flipY) flipIt(srObject.dimY);
    if (srObject.flipZ) flipIt(srObject.dimZ);
    if (srObject.currentDimX != srObject.dimX) {
      switchIt("x", srObject.dimX);
      srObject.currentDimX = srObject.dimX;
    }
    if (srObject.currentDimY != srObject.dimY) {
      switchIt("y", srObject.dimY);
      srObject.currentDimY = srObject.dimY;
    }
    if (srObject.currentDimZ != srObject.dimZ) {
      switchIt("z", srObject.dimZ);
      srObject.currentDimZ = srObject.dimZ;
    }
    srObject.datarevision++;
    srObject.layout2d.datarevision = srObject.datarevision;
    srObject.layout3d.datarevision = srObject.datarevision;
    
    for(let index = 0; index < srObject.traces.length; index ++) {
      if (srObject.traces[index].marker) {
        srObject.traces[index].marker.color = srObject.colorList[index];
      }
      AddLayoutFeatures(srObject.traces[index]);
    }
    srObject.layout2d.shapes.pop();
    srObject.layout2d.shapes.pop();
    
    if (!switch2d3d) {
      Plotly.react('graphDiv', srObject.traces , srObject.active3d ? srObject.layout3d : srObject.layout2d, srObject.plotOptions);
    } else {
      Plotly.newPlot('graphDiv', srObject.traces , srObject.active3d ? srObject.layout3d : srObject.layout2d, srObject.plotOptions);
    }
    clearFlip();
  },
}

export default PcaService
import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    ancientMixedModePopNumber: '30',
    selectedPeriod: { id: 0, number: 0, },
    mixedModePeriods: [],
    mixedModeOrderResult: {},
    selectedAncientMixedMode: {},
  }
};

const state = getDefaultState();

const getters = {
  getAncientMixedModePopNumber(state) {
    return state.ancientMixedModePopNumber;
  },
  getSelectedMixedModeResult(state) {
    return state.mixedModeOrderResult;
  },  
  getSelectedAncientMixedModeResult(state) {
    return state.selectedAncientMixedMode;
  },
}

const mutations = {
  setMixedModePeriods(state, mixedModePeriods) {
    state.mixedModePeriods = mixedModePeriods;

    if (state.selectedPeriod.id === 0) {
      state.selectedPeriod = mixedModePeriods[0];
    }
  },
  setMixedModerPeriod(state, selectedPeriod) {
    state.selectedPeriod = selectedPeriod;
  },
  setMixedModeResult(state, mixedModeResult) {
    state.mixedModeOrderResult = mixedModeResult;
  },
  setSelectedAncientMixedModeResult(state, selectedAncientMixedMode) {
    state.selectedAncientMixedMode = selectedAncientMixedMode;
  },

  // reset state
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchAncientMixedModePeriods(context) {
    const queryParams = {
      dataVersion: context.rootGetters['orderResult/getDataVersion'],
      mixedModeType: 0
    }
    return ApiService.post('mixedmode/periods', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setMixedModePeriods', response.result);
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchOrderMixedModeResults(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post(`mixedmode/result`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  updateOrderResultMixedModeResults(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post(`mixedmode/update`, { orderResultId: queryParams.id }, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
